import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";

import {
  MenuLogo,
  HeaderNav,
  HeaderMeta,
  HeaderBrand,
  StyledHeader
} from "./styles";
import UserMenu from "./UserMenu";
import HelpMenu from "./HelpMenu";
import DraftMenu from "./DraftMenu";
import { TooltipInfo } from "./styles";
import IconButton from "../common/IconButton";
import MainNavigation from "./MainNavigation";
import { Plus, Menu } from "styled-icons/feather";
import { useAppState } from "contextApi/appContext";
import { StickyBanner } from "components/common/styles";
import logo from "assets/images/willow-text-color@2x.png";
import { useComposerActions } from "contextApi/composerContext";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { MagicWand } from "@styled-icons/boxicons-solid/MagicWand";
import { theme } from "theme";

const MainHeader = React.memo(() => {
  const dispatch = useDispatch();
  const account = useSelector(state => state.account.data);
  const history = useHistory();
  const match = useRouteMatch({
    path: "*/dashboard",
    exact: false
  });
  const [isExpanded, setExpanded] = useState(false);
  const windowSize = useAppState(state => state.windowSize);
  const ldError = useAppState(state => state.ldError);
  const showPdfAttachment = useAppState(state => state.showPdfAttachment);
  const showWillowAIRevamp = useAppState(state => state.showWillowAIRevamp);
  const openComposer = useComposerActions(actions => actions.openComposer);

  const handleNavClick = useCallback(() => {
    if (windowSize === "xsmall") {
      setExpanded(false);
    }
  }, [windowSize]);

  const toggleMenu = useCallback(() => {
    setExpanded(isExpanded => !isExpanded);
  }, []);

  const openComposerModal = useCallback(() => {
    openComposer();
  }, [openComposer]);

  return (
    <StyledHeader>
      <div className="main">
        <HeaderBrand>
          <Link to={`/accounts/${account.id}/dashboard`}>
            <MenuLogo src={logo} />
          </Link>
        </HeaderBrand>
        <HeaderNav>
          {(windowSize !== "xsmall" || isExpanded) && (
            <MainNavigation
              accountId={account.id}
              wasClicked={handleNavClick}
            />
          )}
        </HeaderNav>
        <HeaderMeta>
          {windowSize === "xsmall" && (
            <IconButton
              icon={Menu}
              onClick={toggleMenu}
              variant={isExpanded ? "primary" : "secondary"}
            />
          )}
          <IconButton
            data-tip={"New Post"}
            id={"headerNewPostButton"}
            variant="green"
            icon={Plus}
            onClick={openComposerModal}
            size={36}
          />
          <TooltipInfo effect={"solid"} place={"bottom"} />

          <DraftMenu />
          <HelpMenu account={account} />
          <UserMenu account={account} />
        </HeaderMeta>
      </div>
      {!ldError && showWillowAIRevamp ? (
        <StickyBanner isVisible={!!match}>
          <p>
            <em>
              <MagicWand size="20" color="#30AAA8"/>
              All new Willow AI available in beta now.
            </em>
            <span>Open the composer and just start writing.</span>
          </p>
          <i
            className="icon-cancel"
            onClick={e => {
              e.currentTarget.parentElement.style.display = "none";
            }}
          />
        </StickyBanner>
      ) : (
        (!!ldError || showPdfAttachment === false) && (
          <StickyBanner isVisible={!!match}>
            <p>
              Announcing Topical Calendars! Never be short of content ideas
              again -{" "}
              <a
                onClick={e => {
                  e.currentTarget.parentElement.parentElement.style.display =
                    "none";
                  dispatch(trackAnalyticsEvent("Calendar GTM Clicked"));
                  history.push(`/accounts/${account.id}/ideas`);
                }}
              >
                subscribe to a calendar here
              </a>
            </p>
            <i
              className="icon-cancel"
              onClick={e => {
                e.currentTarget.parentElement.style.display = "none";
              }}
            />
          </StickyBanner>
        )
      )}
    </StyledHeader>
  );
});

export default MainHeader;
